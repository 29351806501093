const Td = ({children, type}) => {
  const style =
    type === "1" ? "text-zinc-500 dark:text-zinc-400 relative px-4 first:pl-[var(--gutter,theme(spacing.2))] last:pr-[var(--gutter,theme(spacing.2))] border-b border-zinc-950/5 dark:border-white/5 py-4 sm:first:pl-1 sm:last:pr-1" :
      type === "2" ? "" : "";

  return (
    <td className={style}>
      {children}
    </td>
  )
}

export default Td;