const Pagination = ({type, currentPage, totalPages, onPageChange}) => {
  if (type === "1") {
    const pageNumbers = [];
    for (let i = currentPage - 3; i <= currentPage + 3; i++) {
      if (i > 0 && i <= totalPages) {
        pageNumbers.push(i);
      }
    }

    const style = 'text-zinc-600 hover:bg-cyan-200 transition duration-300 float-left px-2 rounded mx-0.5';
    const currentStyle = 'text-white bg-cyan-400 float-left px-2 rounded mx-0.5';

    return (
      <ul className={"inline-block w-full"}>
        <li>
          <button className={style} onClick={() => onPageChange(1)}>≪</button>
        </li>
        {pageNumbers.map((pageNumber) => (
          <li key={pageNumber}
              className={pageNumber === currentPage ? currentStyle : style}>
            <button onClick={() => onPageChange(pageNumber)}>{pageNumber}</button>
          </li>
        ))}
        <li>
          <button className={style} onClick={() => onPageChange(totalPages)}>≫</button>
        </li>
      </ul>
    )
  } else {
    const pageNumbers = Array.from({length: totalPages}, (_, index) => index + 1);
    const style = 'float-left px-2 rounded mx-0.5';
    return (
      <ul className="inline-block">
        {pageNumbers.map((pageNumber) => (
          <li key={pageNumber}
              className={(pageNumber === currentPage ? 'text-white bg-cyan-400 ' : 'text-gray-800 hover:bg-cyan-200 transition duration-300 ') + style}>
            <button onClick={() => onPageChange(pageNumber)}>{pageNumber}</button>
          </li>
        ))}
      </ul>
    );
  }


};

export default Pagination;
