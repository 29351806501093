import List from "../components/List/List";
import {propStore} from "../stores/PropStore";

const Prop = () =>{
  const {getProp} = propStore()
  console.log(getProp("prop.list.type"))
  return (

    <>
      <List type={getProp("prop.list.type") || "listType1"}>

      </List>
      설정
    </>
  )
}

export default Prop