import {create} from "zustand";
import {api} from "../common/Common";

export const propStore = create((set) => ({
  propList : [],
  getPropList : (propList) => {
    if(propList.length !== 0){
      api.get("prop").then((res) => {
        set({propList:res});
      })
    }
    return propList;
  },
  getProp : async (name) => {
    try{
      const {data : response} = await api.get("prop/"+name);
      return response;
    }catch (error){
      console.log(error);
    }
  }
}))