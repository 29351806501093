import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Header from "./layouts/Header";
import Nav from "./layouts/Nav";
import Footer from "./layouts/Footer";
import Main from "./pages/Main";
import Menu from "./pages/Menu";
import NotFound from "./pages/NotFound";
import User from "./pages/User";
import Prop from "./pages/Prop";
import ListPage from "./pages/ListPage";

function App() {
  return (
    <BrowserRouter>
      <div className="App relative pb-12 min-h-dvh">
        <Header></Header>
        <div className={"flex min-h-[calc(100vh-7rem)]"}>
          <Nav></Nav>
          <div className={"flex-row content-center ml-auto mr-auto"}>
            <Routes>
              <Route path="/" element={<Main/>}></Route>
              <Route path={"/menu/*"} element={<Menu/>}></Route>
              <Route path={"/user/*"} element={<User/>}></Route>
              <Route path={"/prop/*"} element={<Prop/>}></Route>
              <Route path={"/listPage/*"} element={<ListPage/>}></Route>
              <Route path={"*"} element={<NotFound/>}></Route>
            </Routes>
          </div>
        </div>
        <Footer></Footer>
      </div>
    </BrowserRouter>
  );
}

export default App;
