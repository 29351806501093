import Title from "../Title/Title";
import PropTypes from "prop-types";
import Table from "../Table/Table";
import Button from "../Button/Button";
import Pagination from "./Pagination";

const List = ({type, title, headers, rows}) => {

  if (type === "1") {
    return (
      <div className={"w-full min-w-0"}>
        <Title>
          {title}
        </Title>
        <div>
          검색어
        </div>
        <div className={"flow-root"}>
          <Table type={"1"} headers={headers} rows={rows}/>
        </div>
        <div>
          <Pagination type={"1"} currentPage={10} totalPages={11} onPageChange={()=>{console.log(2)}}/>
        </div>
      </div>
    )
  } else {
    return (
      <div className={"w-full min-w-0"}>
        <Title>
          {title}
        </Title>
        <div>
          검색어
        </div>
        <div className={"flow-root"}>
          <Table type={"table1"} headers={headers} rows={rows}>
          </Table>
        </div>
        <div>
          페이징
        </div>
      </div>
    )
  }
}

List.defaultProps = {
  children: "children",
  type: "",
  title: "title",
  headers: [{
    label: "h1",
    ref: "h1",
  }, {
    label: "h2",
    ref: "h2"
  }, {
    label: "h3",
    ref: "h3"
  }, {
    label: "h4",
    ref: "h4"
  }, {
    label: "h5",
    ref: "h5"
  }],
  rows: [{
    h1: "t1",
    h2: "t2",
    h3: "t2",
    h4: "t2",
    h5: "t2",
  }, {
    h1: "t1",
    h2: "t2",
    h3: "t2",
    h4: "t2",
    h5: "t2",
  }, {
    h1: "t1",
    h2: "t2",
    h3: "t2",
    h4: "t2",
    h5: "t2",
  }, {
    h1: "t1",
    h2: "t2",
    h3: "t2",
    h4: "t2",
    h5: "t2",
  }, {
    h1: "t3",
    h2: "t2",
    h3: "t2",
    h4: "t2",
    h5: <Button>버트은</Button>
  }]
}

List.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  headers: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
}

export default List