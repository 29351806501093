import {informationStore} from "../../stores/InformationStore";
import {Link} from "react-router-dom";

const Information = () => {
  const {msg, link} = informationStore()
  return (
    <div className={"inline-block w-auto p-2"}>
      {
        link ?
          <Link
            to={link}
            className={"text-blue-300 font-bold"}
          >
            {msg}
          </Link>
          :
          <div
            className={"text-blue-200"}
          >
            {msg}
          </div>
      }
    </div>
  )
}

export default Information