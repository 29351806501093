import {Link} from "react-router-dom";
import {ChevronRight, Home} from "@styled-icons/boxicons-regular";

const Breadcrumb = () => {
  return (
    <nav>
      <ol className="flex">
        <li>
          <Link to={"/"}>
            <Home size={24} title={"Home"}/>
          </Link>
        </li>
        <li className="lx">
          <ChevronRight size={24}/>
        </li>
        <li className="lx">
          <ChevronRight size={24}/>
        </li>
      </ol>
    </nav>
  )
}

export default Breadcrumb