import Draggable from "react-draggable";
import {useRef} from "react";
import PropTypes from "prop-types";
import Button from "../Button/Button";
import {nanoid} from "nanoid";
import {X} from "@styled-icons/boxicons-regular/X";

const Popup = ({title, children, drag, modal, close, width, height, x, y, buttons}) => {
  const nodeRef = useRef(null);
  return (
    <>
      <div
        className={"overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full h-full flex"}>
        <Draggable
          nodeRef={nodeRef}
          defaultPosition={{x: x || 0, y: y || 0}}
          disabled={!drag}
        >
          <div ref={nodeRef}
               className={"relative " + (width || "w-fit") + " " + (height || "h-fit") + " z-20 bg-white rounded-lg shadow"}>
            <div className={"flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600"}>
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                {title}
              </h3>
              <button
                className={"text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"}
                onClick={close}
              >
                <X/>
                <span className="sr-only">Close Popup</span>
              </button>
            </div>
            <div className="p-4 md:p-5 space-y-4">
              {children}
            </div>
            {buttons ?
              <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                {buttons.map((button) => {
                  return (<Button
                    key={nanoid()}
                    type={button.type}
                    click={button.click}
                    className={button.className}
                    color={button.color}
                    form={button.form}
                  >
                    {button.text}
                  </Button>)
                })}
              </div> : null
            }
          </div>
        </Draggable>
      </div>
      {
        modal ?
          <div
            className={"bg-gray-700 opacity-30 z-10 fixed flex justify-center top-0 left-0 right-0 bottom-0"}
          /> :
          null
      }
    </>
  )
}

Popup.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any,
  drag: PropTypes.bool,
  modal: PropTypes.bool,
  close: PropTypes.func.isRequired,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.string,
  height: PropTypes.string,
  buttons: PropTypes.array
}

export default Popup;