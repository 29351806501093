import {userStore} from "../../stores/UserStore";
import Avatar from "../Avatar/Avatar";

const User = () => {
  const {user} = userStore();
  console.log(user)
  return (
    <div>
      <Avatar className={"w-8 h-8 rounded"} alt={"user_image"} src={user.picture}/>
    </div>
  )
}

export default User;