import React from 'react';
import GoogleLoginButton from "../components/Button/GoogleLoginButton";
import IconLogo from "../svgs/IconLogo";
import {Link} from "react-router-dom";
import {userStore} from "../stores/UserStore";
import User from "../components/User/User";

const Header = () => {
  const {user} = userStore()
  return (
    <header className={"sticky top-0 bg-blue-900 h-16 p-4 border-b-blue-500 border-b-2"}>
      <div className={"flex justify-between"}>
        <IconLogo className={"fill-white ease-in-out duration-1000 hover:rotate-90 cursor-pointer"} width={32}
                  height={32}/>
        <Link to={"/"}>
          <h1 className={"text-white font-bold text-3xl"}>Hwac</h1>
        </Link>
        <div>
          {user ?
            <User></User>
            :
            <GoogleLoginButton/>
          }
        </div>
      </div>
    </header>
  )
};

export default Header;