import React, {useEffect} from 'react';
import {menuStore} from "../stores/MenuStore";
import {Link} from "react-router-dom";

const Nav = () => {
  const {getMenuList} = menuStore();
  useEffect(() => {
    getMenuList(()=>{});
    // eslint-disable-next-line
  }, []);
  return (
    <nav className={"hidden md:flex min-w-56 min-h-full bg-white flex-col border-r"}>
      <ul>
        <li></li>
      </ul>
      <Link to={"/menu"} test={"sss"}>메뉴?</Link>
      <Link to={"/prop"} test={"테스트?"}>프롭?</Link>
      <Link to={"/listPage"} test={"테스트???"}>리스트</Link>
      <h1>네비1</h1>
    </nav>
  )
};

export default Nav;