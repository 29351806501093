import Thead from "./Thead";
import Tbody from "./Tbody";
import Tr from "./Tr";
import Td from "./Td";
import Th from "./Th";

const Table = ({type, headers, rows}) => {
  const style =
    type === "1" ? "min-w-full text-left text-sm/6 text-zinc-950 dark:text-white" :
      type === "2" ? "" : "";
  const refList = [];
  const makeHeader = () => {
    const result = [];
    headers.forEach((item, index) => {
      refList.push(item.ref);
      result.push(<Th key={index} type={type}>{item.label}</Th>);
    })
    return result;
  };
  const makeBody = () => {
    const result = [];
    rows.forEach((row, index1) => {
      const tdList = [];
      refList.forEach((ref, index2) => {
        tdList.push(<Td key={index2} type={type}>{row[ref]}</Td>)
      })
      result.push(<Tr key={index1} type={type}>{tdList}</Tr>)
    })
    return result;
  }

  return (
    <table className={style}>
      <Thead type={type}>
        <Tr type={type}>
          {makeHeader()}
        </Tr>
      </Thead>
      <Tbody type={type}>
        {makeBody()}
      </Tbody>
    </table>
  )
}

export default Table;