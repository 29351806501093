import {GoogleLogin, GoogleOAuthProvider} from "@react-oauth/google";
import {jwtDecode} from "jwt-decode";
import {userStore} from "../../stores/UserStore";

const GoogleLoginButton = () => {
  const {setUser} = userStore();
  const clientId = '67153517675-fpkogailam6g9t728nm3tu52hn95e6v8.apps.googleusercontent.com'
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <GoogleLogin
        type={"icon"}
        size={"medium"}
        onSuccess={(res) => {
          let credential = jwtDecode(res.credential);
          credential.provider = "google"
          setUser(credential);
        }}
        onFailure={(err) => {
          console.log(err);
        }}
      />
    </GoogleOAuthProvider>
  );
};

export default GoogleLoginButton