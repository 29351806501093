import {informationStore} from "../stores/InformationStore";
import {useTranslation} from "react-i18next";

const Main = () => {
  const {setInformation} = informationStore();
  const {t} = useTranslation(["page"])
  return (
    <>
      메인 페이지{' '}
      <button onClick={() => {
        setInformation("a", "/menu/1")
      }}>버튼1
      </button>
      <button onClick={() => {
        setInformation("ㅠ", "/menu/2")
      }}>버튼2
      </button>
      <button onClick={() => {
        setInformation("ㅈㅈㅈㅈ", null)
      }}>버튼4
      </button>
      {t("page:test")}
      222
    </>
  )
}

export default Main