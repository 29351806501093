import PropTypes from "prop-types";

const IconLogo = ({width, height, className}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={"0 0 48 48"} className={className}>
      <path
        d="M5.33 0h.83q2.82 0 4.73 3.23.54 1.05.54 2.51V17.1h23.16V6.09q0-4.57 4.45-6Q39.67 0 40.05 0h.8q2.92 0 4.82 3.39.35 1.05.35 1.62v35.58q0 2.63-3.27 4.48-.92.44-1.71.44h-1.27q-3.02 0-4.79-3.68-.39-1.11-.39-2.41V28.4H11.43v11.65q0 3.71-4.29 5.36-.47.1-.79.1H5.08q-2.89 0-4.73-3.49Q0 41.1 0 40.4V5.3Q0 1.84 4.06.19 4.76 0 5.33 0Z"/>
    </svg>
  )
}

IconLogo.defaultProps = {
  width: 48,
  height: 48,
  className: ""
}

IconLogo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string
}
export default IconLogo