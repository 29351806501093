import React from 'react';
import Information from "../components/Footer/Information";

const Footer = () => {
  return (
    <footer className="w-full h-12 bg-blue-900 bottom-0 absolute border-t-blue-500 border-t-2">
      <div className="static">
        <div className="float-left">
          {/*<div className="inline-block w-auto">인포메이션</div>*/}
          <Information className={"inline-block w-auto"}>
            인포
          </Information>
        </div>
        <div className="float-right">
          <div className="inline-block">상태</div>
          <div className="inline-block">아이콘</div>
          <div className="inline-block">알람</div>
        </div>
      </div>
    </footer>)
};

export default Footer;