import {create} from "zustand";
import {api} from "../common/Common";

export const menuStore = create((set) => ({
  menuList : [],
  getMenuList : (menuList) => {
    if(menuList.length !== 0){
      api.get("menu").then((res) => {
        set({menuList:res});
      })
    }
    return menuList;
  },
}))