import {create} from "zustand";
import {api} from "../common/Common";

export const userStore = create((set) => ({
  user : null,
  setUser : (credential) => {
    console.log(`${process.env.REACT_APP_API_URL}`);
    console.log(credential)
    api.post("user/login", credential, {headers: {
        'Content-Type': "application/x-www-form-urlencoded"
      }}).then((res) => {
      set({user:res.data});
    })
  },
}))