import List from "../components/List/List";

const ListPage = () =>{
  return (
    <>
      <List type={"1"} title={"list1"}>

      </List>
      <List type={"2"} title={"list2"}>

      </List>
    </>
  )
}

export default ListPage