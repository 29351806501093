import PropTypes from "prop-types";
import Button from "../Button/Button";
import {nanoid} from "nanoid";
import Breadcrumb from "./Breadcrumb";

const NamePlate = ({title, menu, buttons}) => {
  return (
    <div className={"flex-row content-center ml-auto mr-auto"}>
      <div>
        <Breadcrumb>

        </Breadcrumb>
        <h4>{title}</h4>
      </div>
      <div>2222
        {buttons.map(
          (button) =>
            <Button
              key={nanoid()} click={button.click}>{button.text}</Button>
        )}
      </div>
    </div>
  )
}

NamePlate.propTypes = {
  title: PropTypes.string.isRequired,
  menu: PropTypes.any.isRequired,
  buttons: PropTypes.array
}
export default NamePlate