const Thead = ({children, type}) => {
  return (
    <thead className={
      type === "1" ? "text-zinc-650 dark:text-zinc-400 text-center" :
        type === "2" ? "" : ""
    }>
    {children}
    </thead>
  )
}

export default Thead;