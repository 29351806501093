import NamePlate from "../components/Nav/NamePlate";
import Popup from "../components/Popup/Popup";
import {useState} from "react";
import Text from "../components/Input/Text";

const Menu = () => {
  const [popup, setPopup] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault()
  }

  return (
    <>
      <NamePlate
        title={"Menu"}
        menu={[]}
        buttons={
          [
            {
              text: "Add",
              click: () => {
                setPopup(true);
              }
            }
          ]
        }
      />

      {popup ?
        <Popup
          title={"Add Menu"}
          modal={true}
          close={() => setPopup(false)}
          // x={-100}
          drag={true}
          // width={"w-min"}
          form={true}
          buttons={
            [
              {
                type: "submit",
                click: () => {

                },
                form: "addMenuForm",
                color: "blue",
                text: "Add"
              },
              {
                type: "button",
                click: () => {
                  setPopup(false)
                },
                color: "",
                text: "Cancel"
              },
            ]
          }
        >
          <form
            id={"addMenuForm"}
            onSubmit={handleSubmit}
          >
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">First
                  name</label>
                <div className="mt-2">
                  <input type="text" name="first-name" id="first-name" autoComplete="given-name"
                         className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                </div>
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">Last
                  name</label>
                <div className="mt-2">
                  <input type="text" name="last-name" id="last-name" autoComplete="family-name"
                         className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                </div>
              </div>

              <div className="sm:col-span-4">
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">Email
                  address</label>
                <div className="mt-2">
                  <input id="email" name="email" type="email" autoComplete="email"
                         className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                </div>
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">Country</label>
                <div className="mt-2">
                  <select id="country" name="country" autoComplete="country-name"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                    <option>United States</option>
                    <option>Canada</option>
                    <option>Mexico</option>
                  </select>
                </div>
              </div>

              <div className="col-span-full">
                <label htmlFor="street-address" className="block text-sm font-medium leading-6 text-gray-900">Street
                  address</label>
                <div className="mt-2">
                  <input type="text" name="street-address" id="street-address" autoComplete="street-address"
                         className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                </div>
              </div>

              <div className="sm:col-span-2 sm:col-start-1">
                <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">City</label>
                <div className="mt-2">
                  <input type="text" name="city" id="city" autoComplete="address-level2"
                         className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                </div>
              </div>

              <div className="sm:col-span-2">
                <label htmlFor="region" className="block text-sm font-medium leading-6 text-gray-900">State /
                  Province</label>
                <div className="mt-2">
                  <input type="text" name="region" id="region" autoComplete="address-level1"
                         className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                </div>
              </div>

              <div className="sm:col-span-2">
                <label htmlFor="postal-code" className="block text-sm font-medium leading-6 text-gray-900">ZIP / Postal
                  code</label>
                <div className="mt-2">
                  <input type="text" name="postal-code" id="postal-code" autoComplete="postal-code"
                         className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                </div>
              </div>
            </div>
            <Text
              type={"text"}
              name={"name"}
              className={""}
            />
            <Text
              type={"text"}
              name={"name"}
              className={""}
            />

          </form>
          이거되나
        </Popup>
        : null
      }
    </>
  )
}

export default Menu